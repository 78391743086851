import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';

import Card from '@components/containers/Card';
import LoggedPage from '@components/containers/LoggedPage';
import FlexBox from '@components/containers/FlexBox';
import Input from '@components/elements/Input';
import Anchor from '@components/elements/Anchor';
import Paragraph from '@components/elements/Paragraph';
import Button from '@components/elements/Button';
import Headline from '@components/elements/Headline';

import useCustomerBankAccounts from '@utils/useCustomerBankAccounts';

import { getDocumentInfo } from '@redux/actions/documents';

const ValidBankAccounts = ({ accountId }) => {
  const dispatch = useDispatch();

  const documentType = useSelector(
    ({ settings }) => settings.constants.signableDocumentTypes.MANDATE,
  );

  const {
    loading,
    signableBankAccounts,
  } = useCustomerBankAccounts();

  const [selectedBankAccount, setSelectedBankAccount] = useState(undefined);

  const selectClick = useCallback(() => {
    if (!selectedBankAccount) return;
    dispatch(getDocumentInfo({ bankAccountId: selectedBankAccount, documentType }));
    navigate(getUrl(
      urls.HOME.AUTOGIRO.SIGN_MANDATE,
      { accountId, bankAccountId: selectedBankAccount },
    ));
  }, [dispatch, selectedBankAccount, accountId, documentType]);

  const onChange = (_field, value) => setSelectedBankAccount(value);

  return (
    <LoggedPage
      title="Välj bankkonto"
      previousUrl={getUrl(urls.HOME.AUTOGIRO.MANDATE, { accountId })}
    >
      <Card loading={loading}>
        <FlexBox flexDirection="column" alignItems="center">
          {signableBankAccounts.length ? (
            <>
              <FlexBox
                flexDirection="column"
                alignItems="flexStart"
                margin="regular"
              >
                <Headline
                  fontWeight="bold"
                  fontSize="tiny"
                  as="h2"
                  marginBottom="none"
                  marginRight="small"
                >
                  Välj bankkonto
                </Headline>
                <Input
                  _id="bankAccountId"
                  inputType="select"
                  label="Välj det bankkonto som du vill skapa ett autogiromedgivande för:"
                  value={selectedBankAccount}
                  onChange={onChange}
                  values={signableBankAccounts}
                  hasError={false}
                  defaultOption="Välj alternativ"
                />
                <Paragraph margin="none">
                  <Anchor underlined to={getUrl(urls.HOME.AUTOGIRO.CONNECT_BANK, { accountId })}>+ Anslut nytt bankkonto</Anchor>
                </Paragraph>
              </FlexBox>
              <Button onClick={selectClick} disabled={!selectedBankAccount} size="small">Skapa medgivande</Button>
            </>
          ) : (
            <>
              <FlexBox
                flexDirection="column"
                alignItems="flexStart"
                marginTop="regular"
                marginBottom="big"
              >
                <Paragraph>Du har för närvarande inga bankkonton anslutna.</Paragraph>
              </FlexBox>
              <Button to={getUrl(urls.HOME.AUTOGIRO.CONNECT_BANK, { accountId })} size="small">Anslut nytt bankkonto</Button>
            </>
          )}
        </FlexBox>
      </Card>
    </LoggedPage>
  );
};

ValidBankAccounts.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default ValidBankAccounts;
